import { FormGroup, FormArray } from '@angular/forms';
import { Component } from '@angular/core';
import { isConstructorDeclaration } from 'typescript';

@Component({
  selector: 'app-base-form',
  template: '<div></div>'
})

export abstract class BaseFormComponent {

  public form: FormGroup;

  constructor() { }

  public abstract submit (): void;

  public onSubmit (): void {
    if (this.form.valid) {
      this.submit();
    } else {
      this.markFormAsTouched(this.form);
    }
  }

  public markFormAsTouched(formGroup: FormGroup | FormArray): void{
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if(control.invalid){
        const invalidControl = document.getElementById(`${field}Field`);
        invalidControl.scrollIntoView({ behavior: 'smooth' });
      }
      control.markAsTouched();
      if(control instanceof FormGroup || control instanceof FormArray){
        this.markFormAsTouched(control);
      }
    });
  }

  public reset(): void {
    this.form.reset();
  }

  public applyValidation(field: string): Object {
    return { 'is-invalid': this.verifyValidation(field)};
  }

  private verifyValidation(field: string): boolean {
    return this.form.get(field).invalid && (this.form.get(field).touched);
  }

  public verifyError(field: string, error: string): boolean {
    return this.form.get(field).hasError(error) && (this.form.get(field).touched);
  }
}